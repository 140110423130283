<div class="automation-container">

  <!-- TOP PART -->
  <div class="announcement-container">
    <Announcement></Announcement>
  </div>

  <div class="first-section">
    <!-- NAVBAR -->
    <div class="navbar-container">
      <Navbar></Navbar>
      <Breadcrumb color="light" [links]="[{title: 'Pilotage des équipements'}]"></Breadcrumb>
    </div>

    <!-- HEROBANNER -->
    <div id="automationHerobanner" class="herobanner-container">
      <div>
        <TitleAtom color="yellow" size="small" weight="semi-bold" [text]="cmsData?.data?.herobanner_title_yellow || ''"></TitleAtom>
        <TitleAtom type="h1" color="light" size="x-large" weight="semi-bold" [text]="cmsData?.data?.herobanner_title_h1 || ''"></TitleAtom>
        <ParagraphAtom color="light" size="x-large" weight="semi-bold" [text]="cmsData?.data?.herobanner_title_h2 || ''"></ParagraphAtom>
        <ParagraphAtom color="light" size="intermediate" weight="regular" [text]="cmsData?.data?.herobanner_text_one || ''"></ParagraphAtom>
        <ParagraphAtom color="light" size="intermediate" weight="regular" [text]="cmsData?.data?.herobanner_text_two || ''"></ParagraphAtom>
      </div>

      <VideoYoutubeAtom *ngIf="cmsData?.data?.herobanner_video_link" [video]="cmsData?.data?.herobanner_video_link || ''" width="600px" widthMobile="100%" height="335px" heightMobile="192px"></VideoYoutubeAtom>
    </div>
  </div>

  <!-- HOW ITS WORK -->
  <div id="automationWork" class="functioning-container" *ngIf="cmsData?.data?.operation_img?.url?.trim() !== '' &&
            cmsData?.data?.operation_title?.trim() !== '' &&
            cmsData?.data?.operation_text_one?.trim() !== '' &&
            cmsData?.data?.operation_text_two?.trim() !== '' &&
            cmsData?.data?.operation_title_framed?.trim() !== '' &&
            cmsData?.data?.operation_text_framed?.trim() !== '' &&
            cmsData?.data?.operation_btn_text?.trim() !== ''">
    <Image borderRadius="radius" type="image" [imageExternal]="cmsData?.data?.operation_img?.url || ''" width="600px" mobileWidth="100%"></Image>
    <div>
      <TitleAtom [mobileSize]="28" type="h2" color="dark" weight="semi-bold" size="large" [text]="cmsData?.data?.operation_title || ''"></TitleAtom>
      <ParagraphAtom color="dark" weight="regular" size="intermediate" [text]="cmsData?.data?.operation_text_one || ''"></ParagraphAtom>
      <ParagraphAtom color="dark" weight="regular" size="intermediate" [text]="cmsData?.data?.operation_text_two || ''"></ParagraphAtom>
      <div *ngIf="cmsData?.data?.operation_title_framed?.trim() !== '' || cmsData?.data?.operation_text_framed?.trim() !== ''">
        <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" [text]="cmsData?.data?.operation_title_framed || ''"></ParagraphAtom>
        <ParagraphAtom color="dark" weight="regular" size="intermediate" [text]="cmsData?.data?.operation_text_framed || ''"></ParagraphAtom>
      </div>
      <ButtonAtom *ngIf="cmsData?.data?.operation_btn_text && cmsData?.data?.operation_btn_text?.trim() !== ''" [style]="'basic'" color="yellow" [text]="cmsData?.data?.operation_btn_text || ''" (click)="hideContactModal = false"></ButtonAtom>
    </div>
  </div>

  <!-- PRODUCTS -->
  <div id="automationProducts" class="products-container" *ngIf="cmsData?.data?.products_title?.trim() !== '' && (cmsData?.data?.products_list?? []).length > 0">
    <TitleAtom color="light" align="center" type="h2" weight="semi-bold" size="large" [text]="cmsData?.data?.products_title || ''"></TitleAtom>
    <Image *ngIf="currentWidth > 1000" type="icon" class="apply-top-left" image="apply-top-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="apply-top-right" image="apply-top-right"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="apply-bottom-left" image="apply-bottom-left"></Image>
    <Image *ngIf="currentWidth > 1000" type="icon" class="apply-bottom-right" image="apply-bottom-right"></Image>

    <Image *ngIf="currentWidth < 1000" type="icon" class="apply-top-left" image="apply-top-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="apply-top-right" image="apply-top-right-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="apply-bottom-left" image="apply-bottom-left-mobile"></Image>
    <Image *ngIf="currentWidth < 1000" type="icon" class="apply-bottom-right" image="apply-bottom-right-mobile"></Image>
    <ul *ngIf="(cmsData?.data?.products_list ?? []).length > 0">
      <li *ngFor="let item of cmsData?.data?.products_list">
        <Image borderRadius="radius" type="image" width="576px" [imageExternal]="item?.product_img?.url || ''"></Image>
        <TitleAtom [mobileSize]="24" type="h3" color="light" weight="semi-bold" size="intermediate" [text]="item?.product_title || ''"></TitleAtom>
        <ParagraphAtom color="light" weight="regular" size="intermediate" [text]="item?.product_text || ''"></ParagraphAtom>
      </li>
    </ul>
  </div>

  <!-- CONTROL -->
  <div class="control-container" *ngIf="cmsData?.data?.control_title?.trim() !== '' ||
                                      cmsData?.data?.control_text_one?.trim() !== '' ||
                                      cmsData?.data?.control_text_two?.trim() !== '' ||
                                      cmsData?.data?.control_text_three?.trim() !== '' ||
                                      cmsData?.data?.control_btn_text?.trim() !== '' ||
                                      cmsData?.data?.control_img?.url?.trim() !== ''">
    <div id="automationControl">
      <TitleAtom [mobileSize]="28" type="h2" color="dark" weight="semi-bold" size="large" [text]="cmsData?.data?.control_title || ''"></TitleAtom>
      <ParagraphAtom color="dark" weight="regular" size="intermediate" [text]="cmsData?.data?.control_text_one || ''"></ParagraphAtom>
      <ParagraphAtom color="dark" weight="regular" size="intermediate" [text]="cmsData?.data?.control_text_two || ''"></ParagraphAtom>
      <ul *ngIf="(cmsData?.data?.control_list ?? []).length > 0">
        <li *ngFor="let item of cmsData?.data?.control_list">
          <ParagraphAtom color="dark" weight="semi-bold" size="intermediate" [text]="item?.control_list_item_text || ''"></ParagraphAtom>
        </li>
      </ul>
      <ParagraphAtom color="dark" weight="regular" size="intermediate" [text]="cmsData?.data?.control_text_three || ''"></ParagraphAtom>
      <ButtonAtom *ngIf="cmsData?.data?.control_btn_text && cmsData?.data?.control_btn_text?.trim() !== ''" [style]="'basic'" color="yellow" [text]="cmsData?.data?.control_btn_text || ''" (click)="hideContactModal = false"></ButtonAtom>
    </div>
    <Image type="image" width="600px" mobileWidth="100%" borderRadius="radius" [imageExternal]="cmsData?.data?.control_img?.url || ''"></Image>
  </div>

  <!-- FAQ -->
  <div id="automationFaq" class="faq-container" *ngIf="cmsData?.data?.faq_title?.trim() !== ''">
    <TitleAtom type="h2" size="x-large" weight="semi-bold" color="dark" [text]="cmsData?.data?.faq_title || ''"></TitleAtom>
    <ul>
      <li *ngFor="let faq of faqs">
        <Faq title="{{ faq.data.title }}" [text]="faq.data.body"></Faq>
      </li>
    </ul>
  </div>

  <!-- FOOTER -->
  <div class="footer-container">
    <Footer></Footer>
  </div>
</div>

<ContactMessage title="Demander un devis gratuitement" btnText="Demander un devis gratuitement" userType="particulier" type="modal" [(isHidden)]="hideContactModal"></ContactMessage>
